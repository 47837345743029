<div *ngIf="esUsuarioUE || puedeEditar" class="notifications-wrapper">
    <mat-icon class="notifications-icon" (click)="isOpen = !isOpen"
        [matBadge]="count" matBadgeColor="warn" cdkOverlayOrigin #trigger="cdkOverlayOrigin">notifications</mat-icon>

    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
        (backdropClick)="isOpen=false" cdkConnectedOverlayHasBackdrop>
        <div class="notifications-list mat-elevation-z8">
            <mat-list>
                <div mat-subheader>Notificaciones</div>
                <mat-divider></mat-divider>
                <mat-list-item [class.is-not-read]="notification.isRead === false"
                    (click)="openNotification(notification)" class="notifications-list-item coviar-row"
                    *ngFor="let notification of notifications">
                    <img matListAvatar [src]="notification.sender.avatar">
                    <p matLine>
                        <strong>{{notification.sender.displayName}}: </strong>
                        {{notification.body}}
                    </p>
                    <time mat-line>{{notification.aliasProyecto}} -- {{notification.fecha | date: 'short' }} </time>

                </mat-list-item>
            </mat-list>
        </div>
    </ng-template>

</div>