import { Component, OnInit, OnDestroy } from '@angular/core';
import { IUsuario } from '../../material-component/usuarios-UE/IUsuario';
import { UsuarioService } from '../../material-component/usuarios-UE/services/usuario.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

import {
    HttpClient
} from '@angular/common/http';
import jwtDecode from 'jwt-decode';

import * as _ from 'lodash';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

    constructor(
        private usuarioService: UsuarioService,
        private _http: HttpClient,
        private _router: Router,

    ) {
        
    }

    usuario: IUsuario = null;
    baseNotificationsUrl = environment.serverUrl + '/api/notifications';
    isOpen = false;

    private maxDate = new Date();
    private minDate = new Date();
    count = 0;

    private intervalId: any;
    notifications: Array<INotification> = [];
    puedeEditar: boolean = false;
    esUsuarioUE = false;
    
    ngOnInit() {
        const user: any = jwtDecode(localStorage.getItem('currentUser'));
        this.esUsuarioUE = user.tipoUsuario === 'UsuarioUE';

        this.usuarioService.getUsuarioById(user._id).subscribe(
            (usuario: IUsuario) => {
                this.usuario = usuario;

                this.getNotifications(this.maxDate).subscribe((notis: INotification[]) => {
                    if (notis) {
        
                        notis.forEach(msg => this.notifications.push(msg));
                        this.processNotifications();
        
                        this.intervalId = setInterval(() => {
                            this.getNotificationsFw(this.maxDate)
                        },10000);
                    }
                }, (error: Error) => {
                    this.handleError(error);
                });

            }, (error: Error) => {
                this.handleError(error);
            }
        );

        this.applyRoles();
    }

    ngOnDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    getNotifications(date: Date) {
        return this._http.get(`${this.baseNotificationsUrl}/${this.usuario._id}/${date.valueOf()}`);
    }

    getNotificationsFw(date: Date) {
        return this._http.get(`${this.baseNotificationsUrl}/${this.usuario._id}/${date.valueOf()}/fw`)
            .subscribe((notis: INotification[]) => {
                if (notis && notis.length > 0) {

                    notis.forEach(msg => this.notifications.unshift(msg));
                    this.processNotifications();
                }
            }, (error: Error) => {
                this.handleError(error);
            });
    }

    processNotifications() {
        this.maxDate = _(this.notifications).map(x => new Date(x.fecha)).max() || this.maxDate;
        this.minDate = _(this.notifications).map(x => new Date(x.fecha)).min() || this.minDate;
        this.count = this.notifications.filter(x=>!x.isRead).length;
    }

    private handleError(error: Error) {
        console.log(error);
        // this._snackBar.openFromComponent(ErrorSnackBarComponent, {
        //     duration: 5000,
        //     data: error.message
        // });
    }

    openNotification(notification:INotification){

        const moduleRoute = (this.usuario.tipoUsuario as any).nombreTipoUsuario == 'UsuarioCoviar'
        ? 'proyecto': 'proyecto-UE';
        
        this.notifications.filter(x=>x.idProyecto == notification.idProyecto && x.isRead== false).forEach(x=>x.isRead = true);
        this.processNotifications();        
        this._router.navigate([`/${moduleRoute}/${notification.idProyecto}/mensajes`]);
        this.isOpen = false;
    }

    applyRoles() {
        this.usuarioService.getUsuario().subscribe((usuario) => {
            if (!usuario || !usuario.permisos) {
                return;
            }
            this.puedeEditar = usuario.permisos.editar;
        });
    }

}

export interface IUser {
    displayName: string,
    avatar: string,
    id: string
}

export interface INotification {
    idProyecto: string,
    aliasProyecto: string,
    url: string,
    fecha: Date
    body: string,
    receiver: string,
    sender: IUser,
    isRead: boolean
}