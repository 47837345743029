        
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<app-notifications></app-notifications>
<button *ngIf="usuario" [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <img src="assets/images/users/avatar.jpg" alt="user" class="profile-pic" />
    <span>{{ usuario.apellidoUsuario}}, {{ usuario.nombreUsuario }}</span>
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <!--button mat-menu-item>
        <mat-icon>settings</mat-icon> Configuración
    </button>
    <button mat-menu-item>
        <mat-icon>account_box</mat-icon> Perfil
    </button>
    <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Deshabilitar notificaciones
    </button-->
    <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon> Cerrar sesión
    </button>
</mat-menu>
