import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { ActividadDescripcionDialog } from '../material-component/proyecto-UE/cargar-actividades-proyecto/cargar-actividades-proyecto.component';


@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ActividadDescripcionDialog
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ActividadDescripcionDialog
   ],
  providers: [ MenuItems ]
})
export class SharedModule { }
