import { Injectable } from '@angular/core';
import { UsuarioHttpService } from './usuario-http.service';
import { IUsuario } from '../IUsuario';
import { IUsuarioUE } from '../IUsuarioUE';
import { AuthService } from '../../authorization/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(
    private _httpService: UsuarioHttpService,
    private _authService: AuthService,
    ) {}

  getUsuarios() {
    return this._httpService.getUsuarios();
  }

  getUsuariosUE() {
    return this._httpService.getUsuariosUE();
  }

  getUsuarioById(idUsuario: string) {
    return this._httpService.getUsuarioById(idUsuario);
  }

  createUsuario(usuario: IUsuario) {
    return this._httpService.createUsuario(usuario);
  }

  createUsuarioUE(usuarioUE: IUsuarioUE) {
    return this._httpService.createUsuarioUE(usuarioUE);
  }

  updateUsuario(usuario: IUsuario) {
    return this._httpService.updateUsuario(usuario);
  }

  updateUsuarioUE(usuarioUE: IUsuarioUE) {
    return this._httpService.updateUsuarioUE(usuarioUE);
  }

  deleteUsuario(usuario: IUsuario) {
    return this._httpService.deleteUsuario(usuario);
  }

  deleteUsuarioUE(usuarioUE: IUsuarioUE) {
    return this._httpService.deleteUsuarioUE(usuarioUE);
  }
  getUsuario(){
    const userInfo = this._authService.getUserInfo();
    if (!userInfo){
      return;
    }
    
    return this._httpService.getUsuarioById(userInfo._id);
  }
}
