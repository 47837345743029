import { Component } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { AuthService } from '../../../material-component/authorization/auth/services/auth.service';
import { UsuarioService } from '../../../material-component/usuarios-UE/services/usuario.service';
import { MatSnackBar } from '@angular/material';
import { ErrorSnackBarComponent } from '../../../material-component/shared/custom-snack-bar/error-snack-bar/error-snack-bar.component';
import { IUsuario } from '../../../material-component/usuarios-UE/IUsuario';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AppHeaderComponent {

  userLoggin: boolean = false;
  usuario: IUsuario = null;

  constructor(
    private authService: AuthService,
    private usuarioService: UsuarioService,
    private _snackBar: MatSnackBar
  ) {
    this.userLoggin = Boolean(this.authService.currentUserValue);

    if (this.userLoggin) {
      const user: any = jwtDecode(localStorage.getItem('currentUser'));

      this.usuarioService.getUsuarioById(user._id).subscribe(
        (usuario: IUsuario) => {
          this.usuario = usuario;
          
        }, (error: Error) => {
          this.handleError(error);
        }
      );
    }
  }

  public logout() {
    this.authService.logout();
    window.location.href = '/login';
  }

  private handleError(error: Error) {
    console.log(error);
    this._snackBar.openFromComponent(ErrorSnackBarComponent, {
      duration: 5000,
      data: error
    });
  }
}
