import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './material-component/authorization/auth/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        loadChildren:
          () => import('./material-component/material.module').then(m => m.MaterialComponentsModule),
      },
      {
        path: '',
        redirectTo: '/proyecto',
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      /*{
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
      },*/
      {
        path: '**',
        component: NotFoundComponent,
      }
    ]
  }
];
