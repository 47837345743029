import { ChangeDetectorRef, Component, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import jwtDecode from 'jwt-decode';

import { MenuItems } from '../../../shared/menu-items/menu-items';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [],
})
export class AppSidebarComponent implements OnDestroy, OnChanges {

  @Input() menuToggled: boolean = false;

  mobileQuery: MediaQueryList;
  user: any = null;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems
  ) {
    this.user = jwtDecode(localStorage.getItem('currentUser'));
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.menuToggled = changes.menuToggled.currentValue;
  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
