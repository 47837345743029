import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';


@Component({
  selector: 'app-error-snack-bar',
  templateUrl: './error-snack-bar.component.html',
  styleUrls: ['./error-snack-bar.component.scss']
})
export class ErrorSnackBarComponent implements OnInit {
  errorData: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.errorData = data;
  }

  ngOnInit() {}
}
