<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar
    *ngIf="userLoggin"
    color="primary"
    class="topbar telative"
    [class.red-toolbar]="user.tipoUsuario === 'UsuarioCoviar'"
  >
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->
    <button mat-icon-button (click)="onMenuToggle()" value="sidebarclosed" class="menu-toggle-btn">
      <mat-icon>menu</mat-icon>
    </button>
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand" routerLink="/">
        <img src="/assets/images/logo.png" alt="logo" style='height: 50px; width: 145px;' />
      </a>
    </div>
    <span fxFlex></span>
    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->
    <app-header></app-header>
  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
  >
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav
      *ngIf="userLoggin"
      #snav
      id="snav"
      [class.dark-sidebar]="user.tipoUsuario === 'UsuarioUE'"
      [class.red-sidebar]="user.tipoUsuario === 'UsuarioCoviar'"
      [class.menu-toggled]="menuToggled"
      class="pl-xs"
      [mode]="mobileQuery.matches ? 'side' : 'over'"
      fixedTopGap="0"
      [opened]="mobileQuery.matches"
    >
      <app-sidebar [menuToggled]="menuToggled"></app-sidebar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper" [class.mat-sidenav-content-toggled]="menuToggled">
      <div class="page-content">
        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>
      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>
