import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RouteTitles } from '../constants/cambios.constants';

export const getTitle = (url: string) => {

  // Asegurarse de que la URL no tenga una barra al final
  if (url.endsWith('/')) {
    url = url.slice(0, -1);
  }

  // Buscar coincidencias de la URL con el objeto de títulos
  let moduleTitle = 'Sin Especificar';
  for (const route in RouteTitles) {
    const regex = new RegExp(`^${route.replace(/:[^\s/]+/g, '[^/]+')}$`);  // Reemplazar parámetros con expresión regular
    if (regex.test(url)) {
      moduleTitle = RouteTitles[route];
      break;
    }
  }

  return moduleTitle;
}

@Injectable()
export class CambiosInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = this.router.url;
    const moduleTitle = getTitle(url);

    // Clonar la petición y agregar el header con el título del módulo
    const clonedRequest = req.clone({
      setHeaders: {
        'X-Module-Title': moduleTitle
      }
    });

    return next.handle(clonedRequest);
  }
}
