import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IUsuario } from '../IUsuario';
import { IUsuarioUE } from '../IUsuarioUE';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { environment } from '../../../../environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UsuarioHttpService {

  baseUrl = environment.serverUrl + '/api';

  constructor(private _http: HttpClient) { }

  getUsuarios() {
    return this._http.get<Array<IUsuario>>(`${this.baseUrl}/usuario`)
      .pipe(
        catchError(this._handleError)
      );
  }

  getUsuariosUE() {
    return this._http.get<Array<IUsuarioUE>>(`${this.baseUrl}/usuarioUE`)
      .pipe(
        catchError(this._handleError)
      );
  }

  getUsuarioById(idUsuario: string) {
    return this._http.get<IUsuario>(`${this.baseUrl}/usuario/${idUsuario}`)
      .pipe(
        catchError(this._handleError)
      );
  }

  createUsuario(usuario: IUsuario) {
    return this._http.post<IUsuario>(`${this.baseUrl}/usuario`, usuario, httpOptions)
      .pipe(
        catchError(this._handleError)
      );
  }

  createUsuarioUE(usuarioUE: IUsuarioUE) {
    return this._http.post<IUsuarioUE>(`${this.baseUrl}/usuarioUE`, usuarioUE, httpOptions)
      .pipe(
        catchError(this._handleError)
      );
  }

  updateUsuario(usuario: IUsuario) {
    return this._http.put<IUsuario>(`${this.baseUrl}/usuario/${usuario._id}`, usuario, httpOptions)
      .pipe(
        catchError(this._handleError)
      );
  }

  updateUsuarioUE(usuarioUE: IUsuarioUE) {
    return this._http.put<IUsuarioUE>(`${this.baseUrl}/usuarioUE/${usuarioUE.usuario._id}`, usuarioUE, httpOptions)
      .pipe(
        catchError(this._handleError)
      );
  }

  deleteUsuario(usuario: IUsuario) {
    return this._http.delete(`${this.baseUrl}/usuario/${usuario._id}`)
      .pipe(
        catchError(this._handleError)
      );
  }

  deleteUsuarioUE(usuarioUE: IUsuarioUE) {
    return this._http.delete(`${this.baseUrl}/usuarioUE/${usuarioUE._id}`)
      .pipe(
        catchError(this._handleError)
      );
  }

  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Ocurrio un error:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend retorno el error  ${error.status}, ` +
        `con el cuerpo: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(`Algo no salio bien, Por favor intente de nuevo mas tarde. ${error.error}`);
  }
}
