<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <div></div>
            <h1>404</h1>
        </div>
        <h2>¡La página solicitada no existe!</h2>
        <p>
            La página que está buscando podría haberse eliminado si se cambia su nombre o no
            está disponible temporalmente.
        </p>
        <a routerLink="/">Inicio</a>
    </div>
</div>