import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { ChartistModule } from 'ng-chartist';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
import { NotificationsModule } from './material-component/notifications/notifications.module';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { IndicadoresComponent } from './indicadores/indicadores.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from './material-component/authorization/auth/interceptors/http.interceptor';
import { CambiosInterceptor } from './material-component/cambios/interceptor/cambios.interceptor';

registerLocaleData(localeES, 'es');

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    NotFoundComponent,
    IndicadoresComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    ChartistModule,
    RouterModule.forRoot(AppRoutes),
    NotificationsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CambiosInterceptor, multi: true },  // Nuevo interceptor
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: ThemeService
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
