import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { AuthService } from '../../material-component/authorization/auth/services/auth.service';


/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.scss'],
})
export class FullComponent implements OnDestroy {

  mobileQuery: MediaQueryList;
  userLoggin: boolean = false;
  user: any = null;
  menuToggled: boolean = false;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authService: AuthService
  ) {
    this.userLoggin = Boolean(this.authService.currentUserValue);
    if (this.userLoggin) {
      this.user = jwtDecode(localStorage.getItem('currentUser'));
    }
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onMenuToggle() {
    this.menuToggled = !this.menuToggled;
  }
}
