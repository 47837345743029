<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<style>
  ::ng-deep.mat-sidenav {
    min-width: 70px !important;
  }
  ::ng-deep.mat-drawer-inner-container {
    overflow-y: auto !important;
    width: 100%;
  }
</style>
<mat-nav-list appAccordion>
  <ng-template ngFor let-menuitem [ngForOf]="menuItems.getMenuitem()">
    <h2 [class.subheader-toggled]="menuToggled" matSubheader *ngIf="menuitem.type === 'header'">{{ menuitem.name }}</h2>
    <mat-list-item
      appAccordionLink
      routerLinkActive="selected"
      group="{{ menuitem.state }}"
      *ngIf="menuitem.type === 'link'"
      [class.mat-list-item-toggled]="menuToggled"
    >
      <a
        appAccordionToggle
        [routerLink]="['/', menuitem.state]"
        [class.nav-link-coviar]="user.tipoUsuario === 'UsuarioCoviar'"
      >
        <mat-icon
          [class.nav-link-coviar]="user.tipoUsuario === 'UsuarioCoviar'"
          [matTooltip]="menuitem.name"
          >{{ menuitem.icon }}</mat-icon
        >
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span
          class="label label-{{ badge.type }}"
          *ngFor="let badge of menuitem.badge"
          >{{ badge.value }}</span
        >
      </a>
    </mat-list-item>
  </ng-template>
</mat-nav-list>
