export const RouteTitles = {

  // coviar
  '/': 'Proyectos',
  '/proyecto': 'Proyectos',

  // UEs
  '/proyecto-UE/:id': 'Formulacion - Datos Generales',
  '/proyecto-UE/:id/descripcion': 'Formulacion - Descripcion',
  '/proyecto-UE/:id/cargar-contrapartes': 'Formulacion - Contraparte',
  '/proyecto-UE/:id/componentes': 'Formulacion - Componentes',
};
