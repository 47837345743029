import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NotificationsComponent } from './notifications.component';
import {
  
    MatIconModule,        
    MatSnackBarModule,
    MatBadgeModule,    
    MatFormFieldModule,
    MatListModule,

  } from '@angular/material';

@NgModule({
    declarations: [
        NotificationsComponent
    ],
    imports: [
      CommonModule,
      FormsModule,   
      MatIconModule,      
      MatSnackBarModule,
      MatBadgeModule,
      OverlayModule,
      MatFormFieldModule,
      MatListModule,
    ],
    bootstrap:[NotificationsComponent],
    exports: [
        NotificationsComponent 
    ]
  })
  export class NotificationsModule {}