import { Token } from '../models/token';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { UsuarioHttpService } from '../../../usuarios-UE/services/usuario-http.service';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // TODO: averiguar de donde obtener AuthenticationType y apiKey
  httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    params: {}
  };

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  private currentUserSubject: BehaviorSubject<Token>;
  public currentUser: Observable<Token>;

  private apiURL: string;

  constructor(
    private http: HttpClient,
    private _usuarioHttpService: UsuarioHttpService
  ) {


    this.currentUserSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.apiURL = environment.serverUrl;

    this.validateLastLogin();

  }

  validateLastLogin() {
    const lastLogin = localStorage.getItem('lastLogin');
    const token = localStorage.getItem('currentUser');
    if (!token) {
      return;
    }

    const user: any = jwtDecode(token);
    if (!user.nombre) {
      return this.logout();
    }

    if (!lastLogin && (user || this.currentUserValue)) {
      return this.logout();
    }

    if (!user || !lastLogin) {
      return;
    }

    var hours = Math.abs(Date.now() - Number(lastLogin)) / 36e5;
    if (hours > 1) {
      this._usuarioHttpService.getUsuarioById(user._id).subscribe((data) => {
        if (!data) {
          this.logout();
        } else {
          localStorage.setItem('lastLogin', Date.now().toString());
        }
      });
    }
  }

  getUserInfo(): { _id: string } {
    const token = localStorage.getItem('currentUser');
    if (!token) {
      return;
    }

    const user: any = jwtDecode(token);
    return user;
  }

  public get currentUserValue(): Token {
    return this.currentUserSubject.value;
  }

  login(formValues: any) {
    return this.http.post(`${this.apiURL}/api/usuario/login`, formValues)
      .pipe(
        map((response: any) => {
          if (response && response.token) {
            localStorage.setItem('currentUser', JSON.stringify(response.token));
            localStorage.setItem('lastLogin', Date.now().toString());
            this.currentUserSubject.next(response.token);

            return response;
          }
        }),
        catchError(this.handleError<any>('login', []))
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('lastLogin');
    this.currentUserSubject.next(null);
  }

  restore(formValues: any) {
    return this.http.post(`${this.apiURL}/api/usuario/restore`, formValues)
      .pipe(
        map((response: any) => {
          return formValues;
        }),
        catchError(this.handleError<any>('login', []))
      );
  }

  restore2(formValues: any) {
    return this.http.post(`${this.apiURL}/api/usuario/restore2`, formValues)
      .pipe(
        map((response: any) => {
          return formValues;
        }),
        catchError(this.handleError<any>('login', []))
      );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
